<template>
  <div class="md-layout md-alignment-center margin-fix">
    <div class="md-layout-item md-size-80">
      <h2>Roscas</h2>
      <p>Roscas asociadas al producto</p>
      <md-button @click="showDialog = true">Crear nueva</md-button>
      <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Nueva rosca</md-dialog-title>
      <div class="md-layout md-alignment-center margin-fix">
        <p>Agrega una nueva rosca para tu producto</p>
        <div class="md-layout-item md-size-80">
          <md-field class="input-fix">
            <label for="screw-name">Nombre de rosca</label>
            <md-select name="screw-type" id="screw-type" v-model="$v.screw.screw_id.$model">
              <md-option v-for="s in list" :value="s.id">{{s.description}}</md-option>
            </md-select>
          </md-field>
          <div v-if="$v.screw.screw_id.$dirty">
            <span class="md-helper-text" v-if="!$v.screw.screw_id.required">Campo requerido</span>
          </div>
        </div>
      </div>
      <md-dialog-actions class="margin-fix">
        <md-button class="md-primary" @click="onCancel">Cerrar</md-button>
        <md-button class="md-primary" @click="onConfirm">Crear</md-button>
      </md-dialog-actions>
      </md-dialog>
      <div class="md-layout-item">
        <md-table>
          <md-table-row>
            <md-table-head md-numeric>ID</md-table-head>
            <md-table-head>ID Rosca</md-table-head>
            <md-table-head>Rosca</md-table-head>
            <md-table-head>Eliminar</md-table-head>
          </md-table-row>
          <md-table-row v-if="products.length < 1">
            <Empty />
          </md-table-row>
          <md-table-row v-else v-for="p in products">
            <md-table-cell md-numeric>{{p.id}}</md-table-cell>
            <md-table-cell>{{p.screw_id}}</md-table-cell>
            <md-table-cell>{{p.screws.description}}</md-table-cell>
            <md-table-cell>
              <md-button class="md-icon-button" @click="deleteProductScrew(p.id)">
                <md-icon>delete</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from '@/components/Common/Empty.vue'
import validations from '@/validations/screws'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Screws',
  components: {
    Empty
  },
  data() {
    return {
      screw: {
        screw_id: ''
      },
      showDialog: false,
    }
  },
  mounted() {
    this.productScrews(this.$route.params.id)
    this.screws()
    Object.assign(this.$data, this.$options.data.apply(this))
  },
  validations: validations,
  computed: {
    ...mapGetters({products: 'productScrews/listProductScrews', list: 'screws/listScrews'})
  },
  methods: {
    ...mapActions({productScrews: 'productScrews/getProductScrews', screws: 'screws/getScrews', deleteProductScrew: 'productScrews/deleteProductScrews'}),
    onConfirm () {
      if (!this.$v.$invalid) {
        this.screw.product_id = this.$route.params.id
        this.$store.dispatch('productScrews/addProductScrews', this.screw)
        this.showDialog = false
        this.cleanUp()
      }
    },
    cleanUp() {
      this.$data.screw = this.$options.data.apply(this).screw
      this.$v.$reset()
    },
    onCancel () {
      this.showDialog = false
    }
  }
}
</script>
