<template>
  <div>
    <Loader class="margin-fix" v-if="loader" />
    <div class="margin-fix" v-else>
      <div class="md-layout md-alignment-center">
        <div class="md-layout-item md-size-80 margin-fix">
          <router-link :to="{name: 'Products'}" class="margin-fix">
            <md-icon>chevron_left</md-icon> Volver al listado
          </router-link>
        </div>
      </div>
      <div class="md-layout md-alignment-center margin-fix">
        <div class="md-layout-item md-size-20">
          <div v-if="show.image == '' || show.image === null">
            <span class="md-caption">Imagen no cargada</span>
          </div>
          <div v-else>
            <img :src="show.image" class="image-fix">
          </div>
        </div>
        <div class="md-layout-item md-size-50">
          <h2>{{show.sku}}</h2>
          <p>{{show.description}}</p>
          <p>OEM: {{show.oem}}</p>
          <md-table>
            <md-table-row>
              <md-table-head>SKU</md-table-head>
              <md-table-head>Categoría</md-table-head>
              <md-table-head>Piezas</md-table-head>
            </md-table-row>
            <md-table-row>
              <md-table-cell>{{show.sku}}</md-table-cell>
              <md-table-cell>{{show.category.category}}</md-table-cell>
              <md-table-cell>{{show.pieces}}</md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </div>
      <div class="md-layout md-alignment-center margin-fix">
        <div class="md-layout-item md-size-80">
          <h2>Especificaciones</h2>
          <p>Detalles del producto seleccionado</p>
          <md-table class="top">
            <md-table-row>
              <md-table-head md-numeric>Diámetro Int.</md-table-head>
              <md-table-head>Diámetro Int. 2</md-table-head>
              <md-table-head>Diámetro Ext</md-table-head>
              <md-table-head>Diámetro Ext. 2</md-table-head>
              <md-table-head>Rosca</md-table-head>
            </md-table-row>
            <md-table-row>
              <md-table-cell md-numeric>{{show.internal_diameter_1 || '-'}}</md-table-cell>
              <md-table-cell>{{show.internal_diameter_2 || '-'}}</md-table-cell>
              <md-table-cell>{{show.external_diameter_1 || '-'}}</md-table-cell>
              <md-table-cell>{{show.external_diameter_2 || '-'}}</md-table-cell>
              <md-table-cell>{{show.screw || '-'}}</md-table-cell>
            </md-table-row>
          </md-table>
          <md-table class="top">
            <md-table-row>
              <md-table-head md-numeric>Notas</md-table-head>
              <md-table-head>Alto</md-table-head>
              <md-table-head>Largo</md-table-head>
              <md-table-head>Ancho</md-table-head>
              <md-table-head>Micron</md-table-head>
              <md-table-head>Contiene</md-table-head>
              <md-table-head>UPC</md-table-head>
            </md-table-row>
            <md-table-row>
              <md-table-cell md-numeric>{{show.connections || '-'}}</md-table-cell>
              <md-table-cell>{{show.height|| '-'}}</md-table-cell>
              <md-table-cell>{{show.length|| '-'}}</md-table-cell>
              <md-table-cell>{{show.width|| '-'}}</md-table-cell>
              <md-table-cell>{{show.micron|| '-'}}</md-table-cell>
              <md-table-cell>{{show.standpipe|| '-'}}</md-table-cell>
              <md-table-cell>{{show.upc|| '-'}}</md-table-cell>
              <!-- <md-table-cell></md-table-cell> -->
            </md-table-row>
          </md-table>
        </div>
      </div>
      <References />
      <Screws />
    </div>
  </div>
</template>
<script>
import References from '@/components/Products/References.vue'
import Screws from '@/components/Products/Screws.vue'
import Loader from '@/components/Common/Loader.vue'
import { mapGetters, mapActions } from 'vuex'
import auth from '@/mixins/auth'
export default {
  name: 'ShowProduct',
  mixins: [auth],
  components: {
    References, Screws, Loader
  },
  data() {
    return {
      loader: true
    }
  },
  mounted() {
    this.product(this.$route.params.id).then(() => {
      this.loader = false
    })
  },
  computed: {
    ...mapGetters({show: 'products/listProduct'})
  },
  methods: {
    ...mapActions({product: 'products/getProduct'})
  }
}
</script>
