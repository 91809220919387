import {required, maxLength, minLength} from 'vuelidate/lib/validators'

export default {
  reference: {
    brand_id: {
      required
    },
    reference: {
      required
    }
  }
}
